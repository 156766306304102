import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            message: "AAAA"
        }
    }

    componentDidMount() {
        this.checkLanguage();
    }
    checkLanguage() {
        this.setState({
           "language":sessionStorage.getItem("language") ? sessionStorage.getItem("language") : "arabic"
        })
    }

    render() {
        return <>
            <Header/>
            <section id="home">
         <div className="container">
            <div className="banner-text row">
               <div className="col-md-12">
                  <div className="col-md-8">
                     <div className="inner-banner-text">
                        <h1>Salaries... Reviews. Jobs.<span className="small-text"> Find the right fit for you.</span></h1>
                        <p>Posting a job review is just a click away. Signup on our <br></br>portal and share your work experiences with the <br></br>world at large.
                        </p>
                        <div className="play-store-btn">
                           <a href="https://play.google.com/store/apps/details?id=com.app.kaziom" target="_blank"><img src="admin/dist/img/available-soon-apps.png" alt=""/></a>
                           <a href="https://apps.apple.com/us/app/kaziom/id1562348010" target="_blank"><img src="admin/dist/img/play_store_en2.png" alt=""/></a>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-4">
                     <div className="banner_mobile">
                        <img src="admin/dist/img/forum-screen.png" alt=""/>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         </section>
            <section className="about-us" id="about_us">
         <div className="container scroll">
            <div className="row">
               <div className="col-md-12">
                  <div className="inner-aboutus">
                    <h3>About Us</h3>
                     <img src="admin/dist/img/line.png" alt=""/>
                     <p>We help people share their opinions about the work they do. In the world of digital surveillance, we make sure that reviews can be posted anonymously to avoid any conflicts. Our motto is simple, every single individual has different perspectives about the same job. Thus, they need a platform to share it fearlessly and let people know about the pros and cons of a company or job profile. Starting from interview questions, to salaries, the scope of promotion, benefits, work culture, management styles, we are going to be the voice of the common man who are slogging day and night out. We ask specific questions so that the most honest reviews can come at the forefront of our pages.</p>
                    <p>At Kaziom, we also allow job postings, office photos and benefits reviews, average salaries and company reviews to help job aspirants get the right kind of information on the internet. No fake reviews and ratings or even slander campaigns. Unlike any other job sites here you will get all the information by those who know the company best ie employees. With millions of companies and employees around the world, it's time you get all the information before starting your career. </p>
                     <hr className="line"/>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="services" id="features_sec">
         <div className="container scroll">
            <div className="">
               <div className="col-md-12">
                  <div className="inner-aboutus">
                     <h3>Features</h3>
                     <img src="admin/dist/img/line.png" alt=""/>
                  <p>Check out all the parameters where you can review the company and share your thoughts. Your opinion will change the future of millions.</p>
                  </div>
                  <div className="row ser-inner">
                     <div className="col-md-3">
                        <div className="main-blok-sec">
                           <div className="overlay-image">
                              <img src="admin/dist/img/shape.png" className="shape-img" alt=""/>
                              <div className="services-block">
                                 <div className="inner-ser-block">
                                    <img src="admin/dist/img/company.png" alt=""/>
                                    <h3>Company</h3>
                                   <p>Post about the pros and cons of the company you are working in. Don’t forget to mention the salary.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-3">
                        <div className="main-blok-sec">
                           <div className="overlay-image">
                              <img src="admin/dist/img/shape.png" className="shape-img" alt=""/>
                              <div className="services-block">
                                 <div className="inner-ser-block">
                                    <img src="admin/dist/img/job.png" alt=""/>
                                    <h3>Job</h3>
                                    <p>Boring? Stressful? Enjoyable? Tell us more about the job you do.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-3">
                        <div className="main-blok-sec">
                           <div className="overlay-image">
                              <img src="admin/dist/img/shape.png" className="shape-img" alt=""/>
                              <div className="services-block">
                                 <div className="inner-ser-block">
                                    <img src="admin/dist/img/salary.png" alt=""/>
                                    <h3>Salary</h3>
                                    <p>Write about your current salary in the present company</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-3">
                        <div className="main-blok-sec">
                           <div className="overlay-image">
                              <img src="admin/dist/img/shape.png" className="shape-img" alt=""/>
                              <div className="services-block">
                                 <div className="inner-ser-block">
                                    <img src="admin/dist/img/interview.png" alt=""/>
                                    <h3>Interview</h3>
                                   <p>Share your interview experiences with us</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="list-sec">
         <div className="container">
            <div className="row">
               <div className="col-md-5">
                  <div className="image-left">
                     <img src="admin/dist/img/muscats.png" alt=""/>
                  </div>
               </div>
               <div className="col-md-7">
                  <div className="text-right-side">
                     <h3>Why Us</h3>
                     <img src="admin/dist/img/line2.png" alt=""/>
                     <ul className="list-secinner">
                        <li><span>Start your career with the best companies</span></li>
                        <li><span>Your most suited job profile is lying with us</span></li>
                        <li><span>Know what’s going in your dream companies</span></li>
                        <li><span>Know what your colleagues are saying</span></li>
                        <li><span>Fresher’s, we have everything that you need</span></li>
                        {/* <li><span>Largest database of companies</span></li>
                        <li><span>Check out the salaries</span></li>
                        <li><span>Post reviews</span></li> */}
                     </ul>
                     <div className="play-btna-sec">
                     <a href="https://play.google.com/store/apps/details?id=com.app.kaziom" target="_blank"><img src="admin/dist/img/availables-soon-apps.png" alt=""/></a>
                     <a href="https://apps.apple.com/us/app/kaziom/id1562348010" target="_blank"><img src="admin/dist/img/play_store_en.png" alt=""/></a>
                  </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      {/* <section className="google-paly-sec">
         <div className="container">
            <div className="row">
               <div className="col-md-5">
               </div>
               <div className="col-md-7">
                  <div className="play-btna-sec">
                     <a href="javascript:void(0)"><img src="admin/dist/img/google-play.png" alt=""/></a>
                     <a href="javascript:void(0)"><img src="admin/dist/img/play-store.png" alt=""/></a>
                  </div>
               </div>
            </div>
         </div>
      </section> */}
      {/* <section className="testimonial-sec" id="testimonial_sec">
         <div className="container">
            <div className="">
               <div className="col-md-12">
                  <div className="inner-aboutus">
                     <h3>Testimonial</h3>
                     <img src="admin/dist/img/line.png" alt=""/>
                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                     </p>
                  </div>
                  <div className="your-class">
                     <div className="col-md-4">
                        <div className="user-list-details">
                           <img src="admin/dist/img/client.png" alt=""/>
                           <p>Lorem Ipsum is simply dummy text of the printing and is typesetting industry.</p>
                           <h3>Amelia</h3>
                           <span>Sr. UI Designer</span>
                        </div>
                     </div>
                     <div className="col-md-4">
                        <div className="user-list-details">
                           <img src="admin/dist/img/client.png" alt=""/>
                           <p>Lorem Ipsum is simply dummy text of the printing and is typesetting industry.</p>
                           <h3>Amelia</h3>
                           <span>Sr. UI Designer</span>
                        </div>
                     </div>
                     <div className="col-md-4">
                        <div className="user-list-details">
                           <img src="admin/dist/img/client.png" alt=""/>
                           <p>Lorem Ipsum is simply dummy text of the printing and is typesetting industry.</p>
                           <h3>Amelia</h3>
                           <span>Sr. UI Designer</span>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      </section> */}
      {/* <section className="frequently-asked-quetions" id="faq_sec">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="inner-aboutus">
                     <h3>Frequently Asked Quetions</h3>
                     <img src="admin/dist/img/line2.png" alt=""/>
                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                     </p>
                  </div>
                  <div className="">
                     <div className="row">
                        <div className="col-md-9 mx-auto">
                           <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                              <div className="panel panel-default">
                                 <div className="panel-heading" role="tab" id="headingOne">
                                    <h4 className="panel-title">
                                       <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                       </a>
                                    </h4>
                                 </div>
                                 <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                    <div className="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.le VHS.</div>
                                 </div>
                              </div>
                              <div className="panel panel-default">
                                 <div className="panel-heading" role="tab" id="headingTwo">
                                    <h4 className="panel-title">
                                       <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                       Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                       </a>
                                    </h4>
                                 </div>
                                 <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                    <div className="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.</div>
                                 </div>
                              </div>
                              <div className="panel panel-default">
                                 <div className="panel-heading" role="tab" id="headingThree">
                                    <h4 className="panel-title">
                                       <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                       Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                       </a>
                                    </h4>
                                 </div>
                                 <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                                    <div className="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                                 </div>
                              </div>
                              <div className="panel panel-default">
                                 <div className="panel-heading" role="tab" id="headingFour">
                                    <h4 className="panel-title">
                                       <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                       Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                       </a>
                                    </h4>
                                 </div>
                                 <div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                                    <div className="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section> */}
      {/* <section className="blog-sec" id="blog_sec">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="inner-aboutus">
                     <h3>Blog</h3>
                     <img src="admin/dist/img/line.png" alt=""/>
                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                     </p>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-md-4">
                  <div className="bolg-sec">
                     <div className="blog-imge">
                        <img src="admin/dist/img/blog-image.png" alt=""/>
                     </div>
                     <div className="blog-text">
                        <h4>Lorum</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                        <span><i className="fa fa-user" aria-hidden="true"></i> Amelia</span>
                        <span className="calender-sec"><i className="fa fa-calendar" aria-hidden="true"></i> 25 Dec-2020</span>
                     </div>
                  </div>
               </div>
               <div className="col-md-4">
                  <div className="bolg-sec">
                     <div className="blog-imge">
                        <img src="admin/dist/img/blog-image.png" alt=""/>
                     </div>
                     <div className="blog-text">
                        <h4>Lorum</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                        <span><i className="fa fa-user" aria-hidden="true"></i> Amelia</span>
                        <span className="calender-sec"><i className="fa fa-calendar" aria-hidden="true"></i> 25 Dec-2020</span>
                     </div>
                  </div>
               </div>
               <div className="col-md-4">
                  <div className="bolg-sec">
                     <div className="blog-imge">
                        <img src="admin/dist/img/blog-image.png" alt=""/>
                     </div>
                     <div className="blog-text">
                        <h4>Lorum</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                        <span><i className="fa fa-user" aria-hidden="true"></i> Amelia</span>
                        <span className="calender-sec"><i className="fa fa-calendar" aria-hidden="true"></i> 25 Dec-2020</span>
                     </div>
                  </div>
               </div>
            </div>
            <div className="read-more-btn">
               <a href="javascript:void(0)">Read More</a>
            </div>
         </div>
      </section> */}
      <Footer/>
        </>;
    }
}

export default Home;
